import * as sharedTypes from '../../constants/action_types/sales_analytics/shared'
import * as profitAndLossTypes from '../../constants/action_types/sales_analytics/profit_and_loss'

import {
  sharedInitialState,
  buildPendingState,
  buildCompletedState,
  buildDataState,
  buildResetDataState
} from './shared'

export const initialState = {
  ...sharedInitialState,
  previousCostSummary: { isLoading: false },
  previousNetMargin: { isLoading: false },
  previousRevenueSummary: { isLoading: false }
}

export function profitAndLossReducer(state = initialState, action) {
  switch (action.type) {
    // General states
    case sharedTypes.SALES_ANALYTICS_PENDING_STATE: {
      return buildPendingState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_COMPLETED_STATE: {
      return buildCompletedState(state, action)
    }
    // Data
    case sharedTypes.SALES_ANALYTICS_LOAD_DATA: {
      return buildDataState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_RESET_DATA: {
      return buildResetDataState(state, action, initialState)
    }
    case profitAndLossTypes.SET_PROFIT_AND_LOSS_DATES: {
      return {
        ...state,
        dates: action.payload
      }
    }
    case profitAndLossTypes.RESET_PROFIT_AND_LOSS: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}
